import React from 'react';
import './AboutMe.css';
import BG from "../../assets/about-Heading.svg";
import Contact from './Contact';
export default function AboutMe() {
  return (
		<main className='about-page'>
			<h1>About Me</h1>
			<div className='about-container'>
				<img src={BG} alt='' className='bg' />
				<img src='images/portrait.jpeg' alt='portrait' className='portrait' />
			</div>
			<p className='bio'>
				Hi,  I'm Kirsten Griffin,  a passionate cinematographer from Dallas,  Texas
				striving to have inclusive works in my legacy. I recently graduated with
				a B.A. from the University of North Texas.
				<br />
				<br />
				Currently,  I’m honing my craft through internships with two dynamic
				companies,  from camera operating live performances to social events.  In addition,  I’m actively engaged as a real estate photographer and
				videographer,  capturing the essence of properties with a keen eye for
				detail. I’m also expanding my skill set by learning drone operation,
				adding a new dimension to my visual storytelling.
				<br />
				<br />
				My goal is to evoke lasting emotions and memories every time you finish
				viewing my work. I want to deliver meaningful images for you to carry
				on.  I thrive in collaborative environments and cherish the opportunity
				to work with other creative minds. So,  if you would like to book with
				me,  please do so below!
			</p>
			<Contact />
		</main>
	);
}
