import './App.css';
import Footer from './Components/Footer/Footer';
import { Navbar } from './Components/Navbar/Navbar';
import { Outlet } from 'react-router-dom';
import { init } from '@emailjs/browser';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
	typography: {
		fontFamily: `"Gruppo", sans-serif`,
	},
});

function App() {
	init({
		publicKey: process.env.REACT_APP_EMAIL_JS_KEY,
	});
	return (
		<ThemeProvider theme={theme}>
			<div className='App'>
				<Navbar></Navbar>
				<Outlet />
				<Footer />
			</div>
		</ThemeProvider>
	);
}

export default App;
