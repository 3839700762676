import React, { useState, useRef, useEffect } from 'react';
import SocialCircle from '../../assets/social-circle.svg';
import './ReelSection.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

export const ReelSection = () => {
	const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
	const [isPlaying, setIsPlaying] = useState(false);

	const [duration, setDuration] = useState(0);
	const videoRef = useRef(null);
	

	// Toggle play/pause functionality
	const togglePlayPause = () => {
		if (videoRef.current.paused) {
			videoRef.current.play();
			setIsPlaying(true);
		} else {
			videoRef.current.pause();
			setIsPlaying(false);
		}
	};

	// Update current time and duration as video metadata loads
	const handleLoadedMetadata = () => {
		setDuration(videoRef.current.duration);
	};
	// Update current time as video plays
	
	useEffect(() => {
		document.addEventListener('fullscreenchange', () => {
			if (!document.fullscreenElement) {
				videoRef.current.controls = false;
				videoRef.current.style.pointerControls = 'none'
			}else {
				videoRef.current.style.pointerControls = ''
			}
		});
		document.addEventListener('keydown', (event) => {
			if(event.code === "Space" && event.target === document.body){
				togglePlayPause();
				event.preventDefault()
			}
		})
		
		document.addEventListener('webkitfullscreenchange', () => {
			if (!document.fullscreenElement) {
				document.exitPointerLock();
				videoRef.current.controls = false;
			videoRef.current.style.pointerControls = 'none'
			}else {
				document.documentElement.requestPointerLock();
				videoRef.current.style.pointerControls = ''
			}
		});
		// Update current time and duration as video metadata loads
		videoRef.current?.addEventListener('loadedmetadata', handleLoadedMetadata);

		// Clean up event listener and interval on component unmount
		return () => {
			videoRef.current?.removeEventListener(
				'loadedmetadata',
				handleLoadedMetadata
			);
		};
	}, [duration]);

	return (
		<section className='reel-section'>
			<div className='social-buttons'>
					<a className='social-btn' target="blank" href="https://www.facebook.com/profile.php?id=61557086201754&mibextid=LQQJ4d">
						<img
							src={SocialCircle}
							alt='facebook account'
							className='social-media-circle'
						/>
						<FacebookIcon className='social-icon' />
					</a>
					<a className='social-btn' target="blank" href="https://www.linkedin.com/in/kirsten-griffin-2b29b7151?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app">
						<img
							src={SocialCircle}
							alt='linkedin account'
							className='social-media-circle'
						/>
						<LinkedInIcon className='social-icon' />
					</a>
					<a className='social-btn' target="blank" href="https://x.com/kirbystene?s=21&t=_vMGwwcX1WhPZR9S8IMscA">
						<img
							src={SocialCircle}
							alt='Twitter Account'
							className='social-media-circle'
						/>
						<XIcon className='social-icon' />
					</a>
				</div>
			<div className='reel-container'>
				<video
					className='reel'
					poster='images/thumbnails/IMH.png'
					ref={videoRef}
					controls={false}
					onClick={togglePlayPause}
					onEnded={() => setIsPlaying(false)}>
					<source src='https://kng-portfolio-videos.s3.amazonaws.com/In-My-Head.mp4' type='video/mp4' />
					Your browser does not support the video tag.
				</video>
				<button onClick={togglePlayPause} className={`play-btn ${isPlaying && !isMobile ? 'pause-btn' : ''}`}>
					<span> {isPlaying && !isMobile ? <PauseIcon fontSize='large'/>:  <PlayArrowIcon fontSize='large'/>}</span>
				</button>
				
			</div>
		</section>
	);
};
